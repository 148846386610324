






















/**
 * @name 推荐
 * */
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { getGoods } from "@/api/index";
import { useDom } from "@/use";
export default defineComponent({
  name: "index",
  props: {
    title: {
      type: String,
      default: "新品推荐",
    },
  },
  setup() {
    const { skipPage } = useDom();
    const itemData = ref([]);
    onMounted(() => {
      getGoodsFn();
    });
    const getGoodsFn = () => {
      getGoods().then((res) => {
        itemData.value = res.data;
      });
    };
    const handleRouter = (row: any) => {
      if (!row.type) return false;
      row.type = row.type == "product" ? "products" : row.type;
      skipPage(`/${row.type}-info`, {
        type: "details",
        id: row.id,
        menuactive: row.dataDictionaryId,
      });
    };
    return {
      itemData,
      handleRouter,
    };
  },
});
