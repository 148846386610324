


















import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "index",
  props: {
    // position: {
    //   type: Array,
    //   default: ["", ""],
    // },
    info: {
      type: Object,
      default: {},
    },
  },
  setup() {
    return {
      // position: [116.473179, 39.993169],
    };
  },
});
