





















































































/**
 * @name 联系我们
 * */
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import bgImg from "@/components/bgImg/index.vue";
import offcanvas from "@/components/offcanvas/index.vue";
import AMap from "@/components/map/index.vue";
import Menu from "@/components/menu/index.vue";
import InfoWarp from "@/components/infowarp/index.vue";
import Recommend from "@/components/recommend/index.vue";
import { useList, getListToTypeFn, useDom } from "@/use";
import { getContactUs, getListToType } from "@/api/index";
export default defineComponent({
  components: {
    bgImg,
    offcanvas,
    AMap,
    Menu,
    InfoWarp,
    Recommend,
  },
  setup() {
    const title = "联系我们";
    const src = ref("contactus/contactus.jpeg");
    // const showMenu = ref(false);
    const activeId = ref(0);
    const { offcanvas, showOffcanvasMenu, changeURLStatic, query } = useDom();
    const infoData = ref({});
    let showUs = ref(true); // true显示关于我们
    const info = [
      {
        strong: "2小时内",
        info: "响应客户需求",
        src: "/contactus/big_1528.png",
      },
      {
        strong: "24小时内",
        info: "提供上门服务",
        src: "/contactus/big_1529.png",
      },
      {
        strong: "48小时内",
        info: "解决问题",
        src: "/contactus/big_1530.png",
      },
    ];
    onMounted(() => {
      getInfo();
      if (query.menuactive) {
        // 加载支持与服务
        activeId.value = Number(query.menuactive);
        showUs.value = false;
      }
    });
    const getInfo = () => {
      getContactUs().then((res) => {
        const { latitude, longitude } = res.data;
        infoData.value = {
          phone: "020-31707963",
          address: "广州市黄埔区开源大道11号广州开发区科技企业加速器A8栋6楼",
          email: "tech@fantechs.com.cn",
          ...res.data,
          position: [longitude || "113.27324", latitude || "23.15792"],
        };
      });
    };
    const arr = ref([
      {
        children: [],
        id: 0,
        label: "联系方式",
      },
      {
        children: [],
        id: 1,
        label: "支持与服务",
      },
    ]);
    const nodeClick = (val: any) => {
      activeId.value = val.id;
      if (val.id === 1) {
        showUs.value = false;
      } else {
        showUs.value = true;
      }
      changeURLStatic("menuactive", val.id);
    };

    return {
      src,
      // showMenu,
      showOffcanvasMenu,
      offcanvas,
      arr,
      nodeClick,
      activeId,
      title,
      infoData,
      showUs,
      info,
    };
  },
});
